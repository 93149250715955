
import Navbar from "@/components/Navbar.vue"; // @ is an alias to /src
import Footer from "@/components/Footer.vue"; // @ is an alias to /src
import DeviceMonitor from "@/components/DeviceMonitor.vue";
import store from "@/store";
import { defineComponent } from "vue";
import { CONSTANTS } from "@/main";

export default defineComponent({
  methods: {},
  components: {
    Navbar,
    Footer,
    DeviceMonitor,
  },
  computed: {
    coreIDs() {
      return store.getters.coreIDs
        .split(CONSTANTS.ADDRESS_SEPERATOR)
        .filter((str: string) => {
          return str != "";
        });
    },
  },
  mounted() {
    const id = new URL(window.location.href).searchParams.get("id");
    console.log(id);
    if (id) {
      store.dispatch("addCoreID", id);
    }
  },
});
