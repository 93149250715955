// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyAlOZkfFoVDB29F_PvVsocaxBVkUt_JSmY",
  authDomain: "systemmonitor-45b7b.firebaseapp.com",
  projectId: "systemmonitor-45b7b",
  storageBucket: "systemmonitor-45b7b.appspot.com",
  messagingSenderId: "487686232564",
  appId: "1:487686232564:web:34e7cd101f81b340da01a1",
  measurementId: "G-KGKJ61NWJ8",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Messaging
import { getMessaging, getToken } from "firebase/messaging";
const messaging = getMessaging(app);
export function requestPermission() {
  getToken(messaging, {
    vapidKey:
      "BDN-YddvcI6Jvtukvx-HltH-rfPhz1WmejeCD-3mx7Im58y-cHeH2g0xyCdc32h2C3L5mbWEmTSBuf8uPOlZhnA",
  })
    .then((currentToken) => {
      if (currentToken) {
        // Send the token to your server and update the UI if necessary
        // ...
        store.commit("currentFirebaseMessageToken", currentToken);
        //update sql database for push subscriptions
        /*
        const coreids = store.getters.coreIDs.split(
          CONSTANTS.ADDRESS_SEPERATOR
        );
        coreids.forEach((coreid: string) => {
          fetch(
            "/scripts/addSubscriber.php?coreid=" +
              coreid +
              "&subscribeID=" +
              currentToken
          );
        });*/
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
        // ...
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // ...
    });
  console.log("Requesting permission...");
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification permission granted.");
    }
  });
}

// Authentication
import {
  getAuth,
  signInWithPopup,
  signInWithRedirect,
  getRedirectResult,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import store from "./store";
import { CONSTANTS } from "./main";
const provider = new GoogleAuthProvider();
const auth = getAuth();
