import { CONSTANTS } from "@/main";
import { createStore, storeKey } from "vuex";

export default createStore({
  state: {
    coreIDs: localStorage.getItem("coreIDs") || "",
    currentFirebaseMessageToken:
      localStorage.getItem("currentFirebaseMessageToken") || "",
  },
  getters: {
    coreIDs(state) {
      return state.coreIDs;
    },
    currentFirebaseMessageToken(state) {
      return state.currentFirebaseMessageToken;
    },
  },
  mutations: {
    // commit
    setCoreIDs(state, payload: string) {
      state.coreIDs = payload;
      //save value for next session
      localStorage.setItem("coreIDs", payload);
    },
    currentFirebaseMessageToken(state, payload: string) {
      state.currentFirebaseMessageToken = payload;
      //save value for next session
      localStorage.setItem("currentFirebaseMessageToken", payload);
    },
  },
  actions: {
    //dispatch
    addCoreID(state, payload: string) {
      let ids = state.getters.coreIDs;
      console.log(ids);
      if (ids !== null) if (ids.includes(payload)) return; //stop if the id already exists in database
      if (ids == null) ids = "";
      state.commit("setCoreIDs", ids + payload + CONSTANTS.ADDRESS_SEPERATOR);
    },
    deleteCoreID(state, payload: string) {
      const ids = state.getters.coreIDs;
      if (!ids?.includes(payload)) return; //stop if the id does not exist in database

      state.commit(
        "setCoreIDs",
        ids.replace(payload + CONSTANTS.ADDRESS_SEPERATOR, "")
      );
    },
  },
  modules: {},
});
