
import store from "@/store";
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      temp_title: "Temperature",
      temp_value: "",
      temp_icon: "thermometer-three-quarters",

      hum_title: "Humidity",
      hum_value: "",
      hum_icon: "tint",

      aux1_title: "Float Switch",
      aux1_value: "",
      aux1_icon: "house-flood",

      aux2_title: "Float Switch",
      aux2_value: "",
      aux2_icon: "house-flood",

      battery_title: "Battery",
      battery_value: "",
      battery_icon: "battery-slash",

      powered_title: "Power",
      powered_value: "",
      powered_icon: "plug",

      signal_title: "Signal Strength",
      signal_value: "",
      signal_icon: "signal",

      published_at_title: "Updated",
      published_at_value: "",
      published_at_icon: "clock",

      notify_title: "Notifications",
      notify_value: "",
      notify_icon: "bell-slash",

      title: "",
    };
  },
  props: {
    coreid: { type: String, default: "" },
  },
  computed: {
    items() {
      return [
        {
          icon: this.temp_icon,
          title: this.temp_title,
          value: this.temp_value,
        },
        {
          icon: this.hum_icon,
          title: this.hum_title,
          value: this.hum_value,
        },
        {
          icon: this.aux1_icon,
          title: this.aux1_title,
          value: this.aux1_value,
        },
        {
          icon: this.aux2_icon,
          title: this.aux2_title,
          value: this.aux2_value,
        },
        {
          icon: this.battery_icon,
          title: this.battery_title,
          value: this.battery_value,
        },
        {
          icon: this.powered_icon,
          title: this.powered_title,
          value: this.powered_value,
        },
        {
          icon: this.signal_icon,
          title: this.signal_title,
          value: this.signal_value,
        },
        {
          icon: this.published_at_icon,
          title: this.published_at_title,
          value: this.published_at_value,
        },
      ];
    },
  },
  methods: {
    //remove core from notifications and view
    deleteCoreID() {
      store.dispatch("deleteCoreID", this.coreid);
      fetch(
        "/scripts/delSubscriber.php?coreid=" +
          this.coreid +
          "&subscribeID=" +
          store.getters.currentFirebaseMessageToken
      );
    },
    toggleNotification() {
      fetch(
        "/scripts/toggleSubscriber.php?coreid=" +
          this.coreid +
          "&subscribeID=" +
          store.getters.currentFirebaseMessageToken
      ).then(() => {
        this.updateView(this.coreid);
      });
    },
    updateView(id: string) {
      fetch("/scripts/status.php?id=" + id)
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          //now that we have new data, lets put it into the main div
          this.title = data["nickname"];
          this.temp_value = data["temperature"] + "°C";
          this.hum_value = data["humidity"] + "%";
          this.aux1_value =
            "Float Switch " + (data["aux1"] != "0" ? "Alarming" : "Normal");
          this.aux2_value =
            "Float Switch " + (data["aux2"] != "0" ? "Alarming" : "Normal");
          this.battery_value =
            data["battery"] == -1 ? "Disconnected" : data["battery"] + "%";
          this.battery_icon =
            data["battery"] == -1 ? "battery-slash" : "battery-bolt";
          this.powered_value =
            data["powered"] == 1 ? "Connected" : "Disconnected";
          this.signal_value = data["connection"] + "%";
          this.published_at_value = data["published_at"];

          this.notify_icon = data["subscribers"].includes(
            store.getters.currentFirebaseMessageToken
          )
            ? "bell"
            : "bell-slash";
        });
    },
  },
  mounted: function () {
    setInterval(() => {
      this.updateView(this.coreid);
    }, 5000);
    this.updateView(this.coreid);
  },
});
