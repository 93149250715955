
import { requestPermission } from "@/firebase";
import store from "@/store";
export default {
  // eslint-disable-next-line
  name: "Navbar",
  data() {
    return {
      coreid: "",
    };
  },
  methods: {
    addCore: (coreid: string) => {
      store.dispatch("addCoreID", coreid);
      //location.reload();
    },
    myFunction(id: string) {
      const x = document.getElementById(id);
      if (x != null && x.previousElementSibling != null)
        if (x.className.indexOf("show") == -1) {
          x.className += " show";
          x.previousElementSibling.className += " theme-d1";
        } else {
          x.className = x.className.replace("show", "");
          x.previousElementSibling.className =
            x.previousElementSibling.className.replace(" theme-d1", "");
        }
    },
    openNav: function () {
      const x = document.getElementById("navDemo");
      if (x != null)
        if (x.className.indexOf("show") == -1) {
          x.className += " show";
        } else {
          x.className = x.className.replace(" show", "");
        }
    },
  },
  mounted() {
    requestPermission();
  },
};

// Used to toggle the menu on smaller screens when clicking on the menu button
